<template>
	<BaseLoading v-if="list.isLoading" />
	<div v-else class="main-wrapper mt-4">
		<CRow class="mb-4">
			<CCol md="9">
				<h4 class="search-weight-title">
					Custom rules
				</h4>
				<div class="description typo-body-2 color-black-45">
					<template v-if="searchWeightList.length">
						Last updated to algolia | {{ list.lastUpdated | datetimeFormat }}
					</template>
					<template v-else>
						There is no rule at the moment. Use add button to add new rule.
					</template>
				</div>
			</CCol>
			<CCol md="3" class="text-right">
				<router-link
					:to="{
						name: 'SearchWeightCreate',
					}"
					class="btn btn-secondary"
					color="secondary"
				>
					<CIcon class="mr-2" name="cil-plus" /> Create
				</router-link>
			</CCol>
		</CRow>
		<template v-if="searchWeightList.length">
			<CRow class="mb-4">
				<CCol lg="4" class="label">
					Rule
				</CCol>
				<CCol lg="2" class="label">
					Type
				</CCol>
				<CCol lg="2" class="label text-right">
					Score
				</CCol>
				<CCol lg="2" class="label">
					Status
				</CCol>
			</CRow>
			<SearchWeightListItem
				v-for="(item, key) in searchWeightList"
				:key="key"
				:default-data="item"
				@click="handleReSyncClick"
			/>
		</template>
		<BaseNoItemBanner v-else text="There are no rule applied for search" />
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SearchWeightListItem from '@/components/SearchWeightListItem.vue';

export default {
	name: 'SerachWeightList',

	components: {
		SearchWeightListItem,
	},
	computed: {
		...mapState('searchWeights', {
			list: 'list',
		}),
		...mapGetters({
			searchWeightList: 'searchWeights/searchWeightList',
		}),
	},
	created() {
		this.getSearchWeights();
	},
	methods: {
		...mapActions({
			getSearchWeights: 'searchWeights/getSearchWeights',
			reSyncSearchWeight: 'searchWeights/reSyncSearchWeight',
		}),
		async handleReSyncClick(id) {
			await this.reSyncSearchWeight({ id });
			await this.getSearchWeights();
		},
	},
};
</script>


<style lang="scss" scoped>
	.search-weight-title {
		margin-bottom: 4px;
	}
</style>
