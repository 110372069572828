<template>
	<CRow class="search-item mb-4">
		<CCol lg="4" class="d-flex align-items-center typo-body-2">
			{{ defaultData.name }}
		</CCol>
		<CCol lg="2" class="d-flex align-items-center typo-body-2">
			{{ defaultData.type }}
		</CCol>
		<CCol lg="2" class="d-flex align-items-center justify-content-end typo-body-2">
			<span class="search-item-score">{{ score }}</span>
		</CCol>
		<CCol lg="2" class="d-flex align-items-center justify-content-between typo-caption color-black-45">
			{{ status }}
			<CButton
				v-if="isSyncing"
				color="tertiary"
				class="icon-resync p-0 m-0"
				@click="syncSearchWeights(defaultData.id)"
			>
				<CIcon name="cil-sync" />
			</CButton>
		</CCol>
		<router-link
			tag="button"
			:disabled="isDisabledLink"
			:to="{
				name: 'SearchWeightEdit',
				params: { id: defaultData.id }
			}"
			class="search-item-edit"
		>
			<CIcon class="icon-edit-search" name="cil-pencil" />
		</router-link>
	</CRow>
</template>

<script>
import { SEARCH_WEIGHT_STATUS } from '../enums/searchWeights';

const mappingStatus = {
	[SEARCH_WEIGHT_STATUS.IN_PROGRESS]: 'Syncing…',
	[SEARCH_WEIGHT_STATUS.FAILED]: 'Sync error',
	[SEARCH_WEIGHT_STATUS.COMPLETED]: 'Completed',
};

export default {
	name: 'SearchWeightListItem',

	props: {
		defaultData: {
			type: Object,
			default: () => ({}),
		},
	},

	computed: {
		score() {
			const score = this.defaultData.score;
			return `${score > 0 ? '+' : ''}${score}`;
		},
		status() {
			return mappingStatus[this.defaultData.status] || mappingStatus[SEARCH_WEIGHT_STATUS.COMPLETED];
		},
		isDisabledLink() {
			return this.defaultData.status !== SEARCH_WEIGHT_STATUS.COMPLETED;
		},
		isSyncing() {
			return this.defaultData.status !== SEARCH_WEIGHT_STATUS.IN_PROGRESS;
		},
	},
	methods: {
		syncSearchWeights(id) {
			this.$emit('click', id);
		},
	},
};
</script>

<style lang="scss" scoped>
	.search-item-score {
		display: inline-block;
		min-width: rem(40);
		text-align: right;
	}

	.search-item-edit {
		border: none;
		background-color: transparent;
		display: inline-block;
		text-align: center;
		width: rem(40);
		margin-left: rem(12);

		.icon-edit-search {
			color: $color-gray-500;
		}

		&:hover,
		&:focus {
			outline: none;

			.icon-edit-search {
				color: $color-orange;
			}
		}

		&:disabled,
		&.disabled {
			cursor: not-allowed;
			opacity: 0.4;
		}
	}

	.icon-resync {
		background-color: transparent;
		color: $color-hypertext;
		cursor: pointer;

		&:hover,
		&:not(:disabled):not(.disabled):active {
			color: $color-hypertext;
			background-color: transparent;
		}
	}
</style>
